import React, { useEffect } from 'react'
import MenuBox from './MenuBox';
import Swiper from 'swiper'
import SwiperMenuPicture from './SwiperMenuPicture'
import Image1 from '../../images/menu/allday/dinner1.jpg'
import Image2 from '../../images/menu/allday/dinner2.jpg'
import Image3 from '../../images/menu/allday/dinner3.jpg'
import Image4 from '../../images/menu/allday/dinner4.jpg'
import Image5 from '../../images/menu/allday/dinner5.jpg'
import Image6 from '../../images/menu/allday/dinner6.jpg'
import Image7 from '../../images/menu/allday/dinner7.jpg'
import { ANJU, BAN_CHAN, SSAL_GARU, SSAM } from './MenuItems/AllDayItems';
import MenuFooterText from '../ExtraComponents/MenuFooterText';

export default function AllDay() {

  let images = [
    { src: Image1, alt: 'allday_image1'},
    { src: Image2, alt: 'allday_image2'},
    { src: Image3, alt: 'allday_image3'},
    { src: Image4, alt: 'allday_image4'},
    { src: Image5, alt: 'allday_image5'},
    { src: Image6, alt: 'allday_image6'},
    { src: Image7, alt: 'allday_image7'},
  ]

  const swiperMenuImages = images.map((item, index) =>
    <SwiperMenuPicture
      src={item.src}
      alt={item.alt}
      key={`${item.alt}_${index}`}
    />
  )

  const newAnjuMenu = ANJU.filter(obj => obj.title !== 'Samchi Gui')

  useEffect(() => {
    new Swiper('#dinnerPicturesSwiper', {
      autoHeight: true,
      initialSlide: 2,
      noSwiping: false,
      slidesPerView: 2,
      centeredSlides: true,
      noSwipingClass:'no-nested-swiping',
      spaceBetween: 20,
      freeMode: true,
      nested: true,
      breakpoints: {
        1200: {
          spaceBetween: 24,
          slidesPerView: 'auto',
          centeredSlides: true,
        }
      }
    })
  }, [])
  return (
    <div className='swiper-slide swiper-no-swiping mt-xl-28 swiper-menu-slide'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <main className='inner-container masonry'>
              <div>
              <MenuBox
                headerText={'Ban-Chan (Side Dishes)'}
                items={BAN_CHAN}
              />
              <MenuBox
                headerText={'Ssam - Korean Lettuce Wrap'}
                subHeaderText="Lettuce, Sesame Leaves, and Ssam Jang"
                items={SSAM}
              />
            </div>


              <MenuBox
                headerText={'Anju (Food for Drink)'}
                items={newAnjuMenu}
              />
              <div>
              <MenuBox
                headerText={'Ssal & Garu (Rice & Flour)'}
                items={SSAL_GARU}
              />
              </div>

            </main>
            <MenuFooterText />
          </div>
        </div>
      </div>
      <div>
        <div className='swiper' id='dinnerPicturesSwiper'>
          <div className='swiper-wrapper no-nested-swiping'>
            { images && swiperMenuImages }
          </div>
        </div>
      </div>
    </div>
  )
}
