import React, { useContext } from 'react'
import Logo from '../../images/footer_logo.svg'
import { LoadingContext } from '../context/LoadingContext'

export default function MainLoadingPage() {

  const { loading, setLoading } = useContext(LoadingContext)

  setTimeout(() => {
    setLoading(false);
  }, 3000);

  return (
    <div className={`main-loading-container ${!loading ? ' loading-done' : ''}`}>
      <img className='header-logo pulse-animation' src={Logo} alt='logo'/>
    </div>
  )
}
