import React, { useRef } from 'react'
import { scrollTo } from '../util/animation';
import { gsap } from 'gsap';

export default function UnderLine({innerElement, colorCSS, selectorId, target}) {

  const underlineRef = useRef(null)

  const slideLeftUnderline = () => {
    const customBorderBottomCss = `#${underlineRef.current.id}`
    gsap.to(customBorderBottomCss, { marginLeft: '-100%'})
  }

  const slideRightUnderline = () => {
    const customBorderBottomCss = `#${underlineRef.current.id}`
    gsap.to(customBorderBottomCss, { marginLeft: '0'})
  }

  return (
    <div className='underline-container'
      onMouseEnter={() => slideLeftUnderline()}
      onMouseLeave={() => slideRightUnderline()}
      onClick={ () => scrollTo(target) }
    >
      {innerElement}
    <div
      ref={underlineRef}
      className={`custom-border-bottom ${colorCSS} w-100 mt-14`}
      id={selectorId}>
    </div>
  </div>
  )
}
