import React from 'react'
import InteriorImage from '../images/interior.png'
import UnderLine from './small/UnderLine';

export default function AboutUs() {

  // TODO: Fix the sizing of the image
  return (
    <section className='row' id='aboutUs'>
      <div className='col-12 col-xl-6 mb-45 mb-xl-0'>
        <div className='mr-45 mr-xl-0'>
          <img src={InteriorImage} className='img-fluid w-100' alt='interior'/>
        </div>
      </div>

      <div className='col-12 col-xl-4'>
        <div className='d-flex align-items-center h-100 ml-xl-75'>
          <div className='d-flex flex-column px-45 px-xl-0'>
            <header className='mb-16 mb-xl-35'>
              <h5 className='font-size-15 font-weight-extra-bold'>About us</h5>
            </header>
            <main>
              <h2 className='font-size-50 font-family-garamond font-weight-semi-bold mb-24' >
              Showcasing a unique and authentic Korean dining.
              </h2>
              <p className='font-size-17 mb-0 mb-xl-75'>
                The ‘Zoomak,’ the name itself means an old Korean tavern/bistro that served travelers with good food, drinks, and even lodging. We wanted to do the same by showcasing an authentic Korean dining experience with a hint of a modern take on it. We are ready to create a new category for Korean cuisine in Vancouver.
              </p>

              <div className='mt-32'>
                <UnderLine
                  innerElement={<button className='clear-btn d-block pb-10 pt-0 pb-xl-14 font-size-15 font-weight-extra-bold text-black px-0 px-xl-7'>Contact Us</button>}
                  colorCSS={'black-border'}
                  selectorId={'contactUsonAboutUs'}
                  target={'#contactUsSection'}
                />
              </div>
            </main>
          </div>
        </div>
      </div>

    </section>
  )
}
