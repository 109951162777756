import React from "react";
import ContactImage from "../images/contact.jpg";

export default function Contact() {
  return (
    <section className="container" id="contactUsSection">
      <div className="row">
        <div className="col-12">
          <div className="inner-container">
            <main className="row contact-us-container">
              <div className="col-12 col-xl-6 p-0 order-2 order-lg-1">
                <div className="mb-0">
                  <img src={ContactImage} className="img-fluid w-100" alt="" />
                </div>
              </div>
              <div className="col-12 col-xl-6 pl-45 px-xl-0 order-1 pt-66 py-xl-0 pb-60">
                <div className="d-flex align-items-center h-100 ml-xl-66">
                  <div className="d-flex flex-column">
                    <header className="mb-20">
                      <h5 className="font-size-15 font-weight-extra-bold">
                        Contact us
                      </h5>
                    </header>
                    <div>
                      <h2 className="font-size-45 font-family-garamond font-weight-semi-bold mb-22 mb-xl-24">
                        Ask us anything <br />
                        or make a reservation.
                      </h2>

                      <ul className="list-group mb-36 mb-xl-84 font-size-14">
                        <li>
                          A <span className="px-2">—</span> 52 Alexander St.
                          Vancouver, BC, Canada V6A 1B4
                        </li>
                        <li>
                          E <span className="pl-4 pl-xl-5 pr-2">—</span>{" "}
                          info@zoomakyvr.com
                        </li>
                        <li>
                          T <span className="pl-4 pl-xl-5 pr-2">—</span>{" "}
                          604.620.1240
                        </li>
                      </ul>

                      <div className="text-uppercase hours-container">
                        <h5 className="ls-3_0 font-weight-extra-bold font-size-15 mb-20">
                          Hours
                        </h5>
                        <div className="font-size-13 mb-20">
                          <div className="mb-6">
                            <div className="d-flex">
                              <div>
                                <p className="font-weight-bold pr-6">
                                  Mon-Thur
                                </p>
                              </div>
                              <div>
                                <p className="mb-6">11:00AM–2:30PM</p>
                                <p>5:00PM–11:30PM</p>
                              </div>
                            </div>
                          </div>
                          <div className="mb-6">
                            <div className="d-flex">
                              <div>
                                <p className="font-weight-bold pr-6">Fri-Sat</p>
                              </div>
                              <div>
                                <p className="mb-6">11:30AM–2:30PM</p>
                                <p>5:00PM–12:00AM</p>
                              </div>
                            </div>
                          </div>
                          <div className="mb-0">
                            <p>
                              <span className="font-weight-bold pr-6">
                                SUNDAY
                              </span>
                              <span>Closed</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </section>
  );
}
