export const LUNCH_ITEMS = [
  {
    title: "Yook Hoe Bibimbap",
    price: "25",
    vegetarian: false,
    subtitles:
      "Spinach, Gosari, Zucchini, Shiitake, Carrot, Konbu, Beef Tartare, Quail Egg Yolk and Served with Seaweed Soup",
  },
  {
    title: "Dolsot the Stone Bowl Rice",
    price: "20",
    vegetarian: true,
    subtitles: "Choice of Beef / Pork / Chicken / Tofu",
  },
  {
    title: "Soondubu Jjigae",
    price: "20",
    vegetarian: false,
    subtitles:
      "Spicy Soft Tofu Stew, Manila Clams, Squid, Prawns, Comes with Rice and Side Dishes",
    additionalElement: "— Sub Shaved Beef Brisket Instead of Seafood"
  },
  {
    title: "Chadol Doenjang",
    price: "20",
    vegetarian: false,
    subtitles:
      "Korean Miso Stew With Shaved Beef Brisket, Comes with Rice and Side Dishes",
      additionalElement: "— Sub Seafood Instead of Beef"
  },
  {
    title: "Squid Deop-Bap",
    price: "20",
    vegetarian: false,
    subtitles:
      "Spicy Squid on the Rice, Poached Egg, Scallion and Crispy Shallot",
  },
  {
    title: "Pocha Udon",
    price: "20",
    vegetarian: false,
    subtitles:
      "Thick Noodle, Dried Anchovy Broth, Fish Cakes, Crown Daisy and Nori",
  },

  {
    title: "Jjam Bbong (White / Red)",
    price: "20",
    vegetarian: false,
    subtitles: "Noodle in Spicy Seafood Broth",
  },
  {
    title: "KFC Rice Bowl (Spicy or Soy)",
    price: "18",
    vegetarian: false,
    subtitles: "Crispy Chicken, Jo-Cheong, and Mayo",
  },
];
export const LUNCH_ITEMS_2 = [
  {
    title: "KFC (Korean Fried Chicken)",
    price: "26",
    vegetarian: false,
    subtitles:
      "Boneless Chicken Leg with a Choice of Sweet Soy Sauce (Sesame Seeds, Cilantro) or Spicy Gochujang Sauce (Peanuts, Scallions)",
  },
  {
    title: "Jeon the Korean Pancake",
    price: "26",
    vegetarian: true,
    subtitles: "Cuttlefish, Asian Chive, Prawns, and Scallops",
  },
  {
    title: "Tteokbokki",
    price: "24",
    vegetarian: true,
    subtitles:
      "Gochu-Jang, Manila Clam, Prawn, Rice Cake, Baby Octopus, and Sesame Leaves",
    additionalElement: "— Add Mozzarella Cheese ($4)",
  },
  {
    title: "Jap-Chae",
    price: "21",
    vegetarian: true,
    subtitles:
      "Glass Noodle, Zucchini, Shimeji Mushroom, Woodear Mushroom, and Assorted Vegetables",
  },
  {
    title: "Kimchi Fried Rice",
    price: "20",
    vegetarian: false,
    subtitles: "Kimchi, Bacon, Poached Egg, and Crispy Shallot",
    additionalElement: "— Add Mozzarella Cheese ($4)",
  },
];
