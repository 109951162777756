import React, { useEffect } from 'react'
import MenuBox from './MenuBox';
import Swiper from 'swiper'
import SwiperMenuPicture from './SwiperMenuPicture'
import Image1 from '../../images/menu/lunch/lunch1.jpg'
import Image2 from '../../images/menu/lunch/lunch2.jpg'
import Image3 from '../../images/menu/lunch/lunch3.jpg'
import Image4 from '../../images/menu/lunch/lunch4.jpg'
import Image5 from '../../images/menu/lunch/lunch5.jpg'
import { LUNCH_ITEMS, LUNCH_ITEMS_2 } from './MenuItems/LunchItems';
import HanSang from '../ExtraComponents/HanSang';
import MenuFooterText from '../ExtraComponents/MenuFooterText';

export default function Lunch() {

  let images = [
    { src: Image1, alt: 'lunch_image1'},
    { src: Image2, alt: 'lunch_image2'},
    { src: Image3, alt: 'lunch_image3'},
    { src: Image4, alt: 'lunch_image4'},
    { src: Image5, alt: 'lunch_image5'},
  ]

  const swiperMenuImages = images.map((item, index) =>
    <SwiperMenuPicture
      src={item.src}
      alt={item.alt}
      key={`${item.alt}_${index}`}
    />
  )

  useEffect(() => {
    new Swiper('#lunchPicturesSwiper', {
      autoHeight: true,
      initialSlide: 2,
      noSwiping: false,
      slidesPerView: 2,
      centeredSlides: true,
      nested: true,
      noSwipingClass:'no-nested-swiping',
      spaceBetween: 20,
      freeMode: true,
      breakpoints: {
        1200: {
          spaceBetween: 24,
          slidesPerView: 'auto',
          centeredSlides: true,
        }
      }
    })
  }, [])

  return (
    <div className='swiper-slide swiper-no-swiping swiper-menu-slide'>
      <div className='container'>
        <main className='row'>
          <div className='offset-xl-4 col-xl-4 col-12'>
            <HanSang />
            <div className='px-xxl-78'>
              <MenuBox
                headerText={'Jeom Sim'}
                items={LUNCH_ITEMS}
              />
              <MenuBox
                headerText={"Zoomak's Best Sellers"}
                items={LUNCH_ITEMS_2}
              />
            </div>
            <MenuFooterText />
          </div>
        </main>
      </div>

      <div>
        <div className='swiper' id='lunchPicturesSwiper'>
          <div className='swiper-wrapper no-nested-swiping'>
            { images && swiperMenuImages }
          </div>
        </div>
      </div>
    </div>
  )
}
