import React from 'react'
import MenuItem from './MenuItem';

export default function MenuBox({ headerText, subHeaderText, items, additionalElement, containerCss }) {

  return (
    <div className={`menu-box masonry-item ${containerCss ? containerCss : ''}`}>
      <header className='pb-15 pb-xl-20 mb-22 mb-xl-30'>
        <h4 className='font-size-30 res-font font-family-garamond font-weight-semi-bold'>
          {headerText}
        </h4>
        { subHeaderText &&
          <p className='font-size-15 mt-6'>{subHeaderText}</p>
        }
      </header>
      <main>
        <ul className='list-group'>
          {items.map(item => <MenuItem item={item} key={`${item.title}-${item.price}`} />)}
        </ul>
        { additionalElement ? additionalElement : null}
      </main>
    </div>
  )
}
