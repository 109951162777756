import React, {useContext} from 'react'
import UnderLine from './small/UnderLine';
import { LoadingContext } from './context/LoadingContext';

export default function SwiperSlide({imageSource, mobileText, desktopText, underlineId }) {

  const { setLoading} = useContext(LoadingContext);

  return (
    <div className='swiper-slide hero-slider-container'>
      <img 
        src={imageSource}
        className='swiper-background-image'
        alt={underlineId}
        loading='lazy'
        onLoad={() => {
          setLoading(false);
        }}
      />
      <div className='container swiper-slide-container' >
        <div className='row'>
          <div className='col-xl-6'>
            <div className='swiper-inner-container'>
              <div className='swiper-text-container w-100'>
                <h1 className='font-size-60 font-family-garamond text-white mb-40'>{mobileText}{desktopText}</h1>
                  <UnderLine
                    innerElement={<button className='clear-btn d-block pb-10 pb-xl-14 font-size-15 font-weight-extra-bold text-white px-3'>View The Menu</button>}
                    colorCSS={'white-border'}
                    selectorId={underlineId}
                    target={'#ourMenu'}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
