import React from 'react'

export default function MenuFooterText() {
  return (
    <div className='text-center mt-42 mt-xl-80 mb-xl-90 mb-84'>
      <p className='font-size-14 line-height-16'>
        * Parties with 6 guests or more will
      </p>
      <p className='font-size-14 line-height-16'>
        add a 20% gratuity on the final check. 
      </p>
    </div>
  );
};
