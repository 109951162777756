import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap';
import LunchMenu from './Menu/Lunch';
import DinnerMenu from './Menu/Dinner';
import DrinksMenu from './Menu/Drinks';
import NightMenu from './Menu/Night';
import Swiper, { Pagination } from 'swiper'
import $ from 'jquery';
import { scrollTo } from './util/animation';

export default function Menu() {

  const swiper = useRef(null)

  const menuHeaderUnderlineAnimation = () => {
    $('#ourMenu').on('mouseenter', '.swiper-pagination-bullet', function(ele) {
      const elementWidth = $( this ).width();
      const elementLeftPosition = $( this ).position().left;

      gsap.to('.swiper-pagination-underline', { left: elementLeftPosition, width: elementWidth})
    })

    $('#ourMenu').on('mouseleave', '.swiper-pagination-bullet', function(ele) {
      setMenuHeaderUnderlineOnLoad();
    })
  }

  const setMenuHeaderUnderlineOnLoad = () => {
    const element = $('.menu-selector .swiper-pagination-bullet-active');
    const elementWidth = element.width();
    const elementLeftPosition = element.position().left;

    gsap.to('.swiper-pagination-underline', { left: elementLeftPosition, width: elementWidth})
  }

  useEffect(() => {

    const hash = window.location.hash
    let initialSlide = 0;

    if (hash) {
      switch (hash) {
        case '#lunchMenu':
          initialSlide = 0
          break;
        case '#dinnerMenu':
          initialSlide = 1
          break;
        case '#allDayMenu':
          initialSlide = 1
          break;
        case '#nightMenu':
          initialSlide = 2
          break;
        case '#drinksMenu':
          initialSlide = 3
          break;
        default:
          initialSlide = 0;
          break;
      }
      scrollTo('#ourMenu', 0, 0);
    }
    window.onresize = function() {
      setMenuHeaderUnderlineOnLoad();
    }

    Swiper.use([Pagination])

    const menu = ['Lunch', 'Dinner', 'Night', 'Drinks']

    let menuSwiper = swiper.current = new Swiper('#menuSwiper', {
      autoHeight: true,
      noSwiping: true,
      initialSlide: initialSlide,
      pagination: {
        el:'.menu-selector',
        clickable: true,
        type: 'bullets',

        renderBullet: function( index, className) {
          return `<span class='font-weight-extra-bold text-uppercase ls-3_0 ${className}' ${index == initialSlide ? 'swiper-pagination-bullet-active' : ''}>${menu[index]}</span>`
        }
      },
    })

    menuSwiper.on('slideChange', function(d) {  
      setMenuHeaderUnderlineOnLoad();
    })
    menuHeaderUnderlineAnimation();
    setMenuHeaderUnderlineOnLoad();
  }, [])

  return (
    <section id='ourMenu'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <header>
              <h1 className='font-size-60 text-center font-weight-semi-bold font-family-garamond mb-34 mb-xl-38'>Our Menu</h1>
              <div className='menu-selector-container'>
                <div className='swiper-pagination-underline'></div>
                <div className='d-flex justify-content-between justify-content-xl-center mb-46 mb-xl-44 menu-selector'>
                </div>
              </div>
            </header>
          </div>
        </div>
      </div>

      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 px-0'>
            <main className='px-4 mx-xl-0'>
              <div className='swiper' id='menuSwiper'>
                <div className='swiper-wrapper'>
                  <LunchMenu />
                  <DinnerMenu />
                  <NightMenu />
                  <DrinksMenu />
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </section>
  )
}
