import React from 'react'
import { SwiperSlide } from 'swiper/react'

export default function SwiperMenuPicture({src, alt}) {

  return (
    <SwiperSlide>
      <img src={src} className='img-fluid w-100' alt={alt}/>
    </SwiperSlide>
  )
}
