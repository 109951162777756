import React, { createContext, useState } from "react";

export const LoadingContext = createContext({
  loading: true,
  setLoading: () => undefined,
});

export const LoadingContextProvider = ({ children }) => {
  const [ loading, setLoading ] = useState(true);

  return (
    <LoadingContext.Provider
      value={{ loading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  )
}