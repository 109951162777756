import React, { useRef } from 'react'
import { scrollTo } from '../util/animation';
import { gsap } from 'gsap';

export default function NavItem({innerElement, colorCSS, selectorId, target, containerCss = null}) {

  const underlineRef = useRef(null)

  const slideLeftUnderline = () => {
    const customBorderBottomCss = `#${underlineRef.current.id}`
    gsap.to(customBorderBottomCss, { marginLeft: '-100%'})
  }

  const slideRightUnderline = () => {
    const customBorderBottomCss = `#${underlineRef.current.id}`
    gsap.to(customBorderBottomCss, { marginLeft: '0'})
  }

  return (
    <div className={`nav-item ${containerCss ? containerCss : ''}`} data-toggle="collapse" data-target=".navbar-collapse.show">
      <div className='underline-container'
        onMouseEnter={() =>  slideRightUnderline()}
        onMouseLeave={() => slideLeftUnderline()}
        onClick={() => scrollTo(target)}
      >
          {innerElement}
        <div
          ref={underlineRef}
          className={`custom-border-bottom ${colorCSS} w-100`}
          id={selectorId}>
        </div>
      </div>
    </div>
  )
}
