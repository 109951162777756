import React, { useEffect } from 'react'
import MenuBox from './MenuBox';
import Swiper from 'swiper'
import SwiperMenuPicture from './SwiperMenuPicture'
import Image1 from '../../images/menu/drinks/drink1.jpg'
import Image2 from '../../images/menu/drinks/drink2.jpg'
import Image3 from '../../images/menu/drinks/drink3.jpg'
import Image4 from '../../images/menu/drinks/drink4.jpg'
import Image5 from '../../images/menu/drinks/drink5.jpg'
import Image6 from '../../images/menu/drinks/drink6.jpg'
import { BEER, COCKTAILS, MAKGEOLLI, NON_ALCOHOL, SAKE, SOJU, WHIKEY_SHOTS, WINE } from './MenuItems/DrinkItems';

export default function Drinks() {

  let images = [
    { src: Image1, alt: 'drink_image1'},
    { src: Image2, alt: 'drink_image2'},
    { src: Image3, alt: 'drink_image3'},
    { src: Image4, alt: 'drink_image4'},
    { src: Image5, alt: 'drink_image5'},
    { src: Image6, alt: 'drink_image6'},
  ]

  const swiperMenuImages = images.map((item, index) =>
    <SwiperMenuPicture
      src={item.src}
      alt={item.alt}
      key={`${item.alt}_${index}`}
    />
  )

  useEffect(() => {
    new Swiper('#drinksPicturesSwiper', {
      autoHeight: true,
      initialSlide: 2,
      noSwiping: false,
      slidesPerView: 2,
      centeredSlides: true,
      noSwipingClass:'no-nested-swiping',
      spaceBetween: 20,
      freeMode: true,
      nested: true,
      breakpoints: {
        1200: {
          spaceBetween: 24,
          slidesPerView: 'auto',
          centeredSlides: true,
        }
      }
    })
  }, [])

  return (
    <div className='swiper-slide swiper-no-swiping mt-xl-28 swiper-menu-slide'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <main className='inner-container masonry'>
              <div>
              <MenuBox
                headerText={'Cocktail (5~6%)'}
                items={COCKTAILS}
                additionalElement={<p className='mt-22 mt-xl-30 font-size-15'>* All have 2oz of liquor</p>}
              />
              <MenuBox
                headerText={'Sake List (14~16%)'}
                items={SAKE}
              />
            </div>

            <div>
              <MenuBox
                headerText={'Beer List (5~7%)'}
                items={BEER}
              />
              <div className='mb-50 mb-xl-75'>
                <MenuBox
                  headerText={'Wine List (12~14%)'}
                  containerCss={'mb-xl-0'}
                  items={WINE}
                  additionalElement={<p className='mt-22 mt-xl-30 font-size-15'>* Wine list curated by @sommforlife</p>}
                />
              </div>

              <MenuBox
                headerText={'Makgeolli (6~7%)'}
                items={MAKGEOLLI}
              />
              </div>

              <div className='mb-50 mb-xl-75'>
              <MenuBox
                headerText={'Soju List (14~25%)'}
                items={SOJU}
              />
              <MenuBox
                headerText={'Whiskey and Shots (43~51%, 1oz)'}
                items={WHIKEY_SHOTS}
              />
              <MenuBox
                headerText={'Non-Alcohol'}
                items={NON_ALCOHOL}
              />
              </div>
            </main>
          </div>
        </div>
      </div>
      <div className='text-center mb-xl-90 mb-84'>
        <p className='font-size-15'>* Taxes are not included</p>
      </div>

      <div>
        <div className='swiper' id='drinksPicturesSwiper'>
          <div className='swiper-wrapper no-nested-swiping'>
            { images && swiperMenuImages }
          </div>
        </div>
      </div>
    </div>
  )
}
