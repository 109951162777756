import React from "react";
import NavItem from "./small/NavItem";
import FooterLogo from "../images/footer_logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footer() {
  return (
    <footer className="mb-50 mb-xl-90">
      <div className="d-flex justify-content-center align-items-center flex-column">
        <div className="image-container mb-68 mb-xl-70">
          <img className="footer-logo" src={FooterLogo} alt="footerLogo" />
        </div>
        <div className="font-size-13 mb-30 footer-links-container px-45">
          <NavItem
            innerElement={
              <button className="clear-btn d-block pb-10 pt-13 px-2 text-black ls-2_0">
                About us
              </button>
            }
            colorCSS={"black-border"}
            selectorId={"navAboutUs"}
            target={"#aboutUs"}
          />
          <NavItem
            innerElement={
              <button className="clear-btn d-block pb-10 pt-13 px-2 text-black ls-2_0">
                Menu
              </button>
            }
            colorCSS={"black-border"}
            selectorId={"navOurMenu"}
            target={"#ourMenu"}
          />
          <NavItem
            containerCss="medium"
            innerElement={
              <button className="clear-btn d-block pb-10 pt-13 px-2 text-black ls-2_0">
                Contact
              </button>
            }
            colorCSS={"black-border"}
            selectorId={"navContactUs"}
            target={"#contactUsSection"}
          />
          <div>
            <a
              className=" "
              href="https://www.instagram.com/zoomakyvr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={["fab", "instagram"]}
                size={"2x"}
                className="text-black"
              />
            </a>
          </div>
        </div>

        <div className="footer-address text-secondary font-size-12 text-uppercase text-center">
          <div className="desktop">
            <p className="mb-2">
              52 Alexander St. Vancouver, BC, Canada
              <span className="px-8"> | </span> MON – THUR 11AM – 2:30PM{" "}
              <span className="px-2"> | </span> 5PM – 11:30PM{" "}
              <span className="px-8"> | </span> FRI – SAT 11:30AM – 2:30PM{" "}
              <span className="px-2"> | </span> 5PM – 12AM{" "}
              <span className="px-8"> | </span> SUN Closed
            </p>
            <p>
              info@zoomakyvr.com <span className="px-16">604.620.1240</span> ©
              {new Date().getFullYear()} zoomak. All rights RESERVED.
            </p>
          </div>

          <div className="mobile font-weight-bold">
            <p className="mb-2">52 Alexander St. Vancouver, BC, Canada</p>
            <p>©{new Date().getFullYear()} zoomak. All rights RESERVED.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
