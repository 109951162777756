import React from 'react'
import NavItem from './small/NavItem';
import Logo from '../images/header_logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function NavBar() {
  return (

    <nav className="navbar navbar-expand-lg">
      <img className='desktop header-logo' src={Logo} alt='logo'/>
      <div className='flex-between mobile w-100'>
        <button style={{fontSize: '0.95rem'}} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <FontAwesomeIcon
            icon={'bars'}
            size='2x'
            className='text-white'
          />
          <i className="fas fa-bars text-white"></i>
        </button>
        <img className='header-logo' src={Logo} alt='logo'/>
        <a style={{fontSize: '0.95rem'}} className="nav-link px-9 py-4" href="https://www.instagram.com/zoomakyvr/" target='_blank' rel="noopener noreferrer">
          <FontAwesomeIcon
            icon={['fab', 'instagram']}
            size={'2x'}
            className='text-white'
          />
        </a>
      </div>

      <div className="collapse navbar-collapse justify-content-end mt-16 mt-xl-0" id="navbarNav">
        <ul className="navbar-nav align-items-center">
          <NavItem
            innerElement={<button className="clear-btn nav-link pb-10 pt-15">About us</button>}
            colorCSS={'white-border'}
            selectorId={'navAboutUs'}
            target={'#aboutUs'}
          />
          <NavItem
            innerElement={<button className="clear-btn nav-link pb-10 pt-15">Menu</button>}
            colorCSS={'white-border'}
            selectorId={'navOurMenu'}
            target={'#ourMenu'}
          />
          <NavItem
            innerElement={<button className="clear-btn nav-link pb-10 pt-15">Contact Us</button>}
            colorCSS={'white-border'}
            selectorId={'navContactUs'}
            target={'#contactUsSection'}
          />
          <li className="nav-item desktop">
            <a className="nav-link" href="https://www.instagram.com/zoomakyvr/" target='_blank' rel="noopener noreferrer">
              <FontAwesomeIcon
                icon={['fab', 'instagram']}
                size={'2x'}
                className='text-white'
              />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}
