export const COCKTAILS = [
  {
    title: 'Ok Sun Fizz',
    price: '16',
    vegetarian: false,
    subtitles: 'Makgeolli, Mezcal, Lemon Juice, Lime Juice, Ginger Syrup, Egg White, Orange Flower Water, and Cucumber Soda'
  },
  {
    title: 'Bloody Tiger',
    price: '15',
    vegetarian: false,
    subtitles: 'Gin, Blood Orange Juice, Lemon Juice, Egg White, and Orange'
  },
  {
    title: 'Soju Negroni',
    price: '15',
    vegetarian: false,
    subtitles: 'Soju, Sweet Vermouth, Campari, Aperol, and Orange Bitters'
  },
  {
    title: 'Paper Crane',
    price: '15',
    vegetarian: false,
    subtitles: 'Whiskey, Aperol, Amaro Nonino, and Yuzu Juice'
  },
  {
    title: 'Purple Reign',
    price: '15',
    vegetarian: false,
    subtitles: 'Butterfly Pea Flower Gin, Ginger Syrup, Lemon Juice, and Burnt Rosemary Sprig'
  },
  {
    title: 'Black Pink',
    price: '15',
    vegetarian: false,
    subtitles: 'Vodka, St. Germain, Lychee Syrup, and Pomegranate Juice'
  },
  {
    title: 'Very Berry Soju',
    price: '15',
    vegetarian: false,
    subtitles: 'Soju, Mixed Berries, Yakult, Mint, and Lemon'
  },
  {
    title: 'Sesame Sour',
    price: '14',
    vegetarian: false,
    subtitles: 'Sesame-Infused Whiskey, Lemon Juice, Ginger Syrup, Egg White, and Sesame Seeds'
  },
]

export const SAKE = [
  {
    title: 'Dasahi 39',
    price: '65 (330ml) / 140 (720ml)',
    vegetarian: false,
    subtitles: 'Junmai Dai Ginjo'
  },
  {
    title: 'Mizubasho',
    price: '45 (300ml) / 99 (720ml)',
    vegetarian: false,
    subtitles: 'Junmai Dai Ginjo'
  },
  {
    title: 'Kozaemon',
    price: '35 (330ml) / 78 (720ml)',
    vegetarian: false,
    subtitles: 'Junmai Ginjo'
  },
  {
    title: 'Meikyoshisui',
    price: '78 (720ml)',
    vegetarian: false,
    subtitles: 'Junmai Ginjo'
  },
  {
    title: 'Hitakami',
    price: '76 (720ml)',
    vegetarian: false,
    subtitles: 'Junmai'
  },
  {
    title: 'Sharaku',
    price: '75 (720ml)',
    vegetarian: false,
    subtitles: 'Junmai'
  },
]

export const BEER = [ 
  {
    title: 'Cass',
    price: '12 (640ml)',
    vegetarian: false,
    subtitles: 'Korean No.1 Lager'
  },
  {
    title: 'Terra',
    price: '11 (640ml)',
    vegetarian: false,
    subtitles: 'Korean, Clean and Crisp Lager'
  },
  {
    title: 'Klaud',
    price: '11 (640ml)',
    vegetarian: false,
    subtitles: 'Korean, European Pale Lager'
  },
  {
    title: 'Sapporo',
    price: '8 (500ml)',
    vegetarian: false,
    subtitles: 'Larger Tall Can'
  },
  {
    title: '33 Acres',
    price: '8 (455ml)',
    vegetarian: false,
    subtitles: '“Sunshine” French Blanche on Tap'
  },
  {
    title: 'Strange Fellow',
    price: '8 (455ml)',
    vegetarian: false,
    subtitles: '“Talisman” West Coast Pale Ale on Tap'
  },
  {
    title: 'Four Wind',
    price: '8 (455ml)',
    vegetarian: false,
    subtitles: 'West Coast IPA on Tap'
  },
  {
    title: 'Red Truck',
    price: '8 (455ml)',
    vegetarian: false,
    subtitles: 'Classic Lager'
  },
]

export const WINE = [
  {
    title: 'Mouton Cadet Heritage Edition Bordeaux',
    price: '55 (750ml)',
    vegetarian: false,
    subtitles: 'Full Bodied Red'
  },
  {
    title: 'Whitehaven Sauvignon Blanc',
    price: '42 (750ml)',
    vegetarian: false,
    subtitles: 'Light Bodied White'
  },
]

export const MAKGEOLLI = [
  {
    title: 'Red Monkey (10.8%)',
    price: '30 (375ml)',
    vegetarian: false,
    subtitles: 'Red Nuruk Rice Wine, Creamy and Rich'
  },
  {
    title: 'Surrey Fresh',
    price: '18 (750ml)',
    vegetarian: false,
    subtitles: ''
  },
];

export const SOJU = [
  {
    title: 'Lee Gang Ju',
    price: '59 (375ml)',
    vegetarian: false,
    subtitles: 'Aroma of Asian Pear, Ginger, Turmeric, and Cinnamon'
  },
  {
    title: 'Hwayo 25%',
    price: '52 (375ml)',
    vegetarian: false,
    subtitles: ''
  },
  {
    title: 'Daejangboo',
    price: '45 (375ml)',
    vegetarian: false,
    subtitles: ''
  },
  {
    title: 'Ilpoom Jinro',
    price: '45 (375ml)',
    vegetarian: false,
    subtitles: ''
  },
  {
    title: 'Won Soju',
    price: '42 (375ml)',
    vegetarian: false,
    subtitles: ''
  },
  {
    title: 'Seoul Night',
    price: '28 (375ml)',
    vegetarian: false,
    subtitles: 'Aroma of Green Plum'
  },
  {
    title: 'Byeol-Bit Chungha',
    price: '18 (375ml)',
    vegetarian: false,
    subtitles: 'Sparkling Sweet Sake'
  },
  {
    title: 'Soju',
    price: '18 (375ml)',
    vegetarian: false,
    subtitles: 'Add Flavour: Yakult, Green Plum, or Yuzu $4'
  },
  {
    title: 'Byeol Bam',
    price: '16 (375ml)',
    vegetarian: false,
    subtitles: 'Barley Soju'
  },
];

export const WHIKEY_SHOTS = [
  {
    title: 'Clase Azul',
    price: '25',
    vegetarian: false,
    subtitles: ''
  },
  {
    title: 'Suntory "Hibiki"',
    price: '16',
    vegetarian: false,
    subtitles: ''
  },
  {
    title: 'Don Julio Reposado',
    price: '14',
    vegetarian: false,
    subtitles: ''
  },
  {
    title: 'Hwayo 53',
    price: '14',
    vegetarian: false,
    subtitles: ''
  },
  {
    title: 'Don Julio Blanco',
    price: '13',
    vegetarian: false,
    subtitles: ''
  },
  {
    title: 'Casamigo blanco',
    price: '13',
    vegetarian: false,
    subtitles: ''
  },
  {
    title: 'Nikka "from the barrel"',
    price: '12',
    vegetarian: false,
    subtitles: ''
  },
  {
    title: 'Shinobu',
    price: '11',
    vegetarian: false,
    subtitles: ''
  },
  {
    title: 'Suntory "Toki"',
    price: '10',
    vegetarian: false,
    subtitles: ''
  },
  {
    title: 'Jose Ceurvo',
    price: '8',
    vegetarian: false,
    subtitles: ''
  },
  {
    title: 'Grey Goose',
    price: '9',
    vegetarian: false,
    subtitles: ''
  },
];

export const NON_ALCOHOL = [
  {
    title: 'Blueberry Yuzuade',
    price: '8',
    vegetarian: false,
    subtitles: 'Blueberries, Mint, and Yuzu Juice'
  },
  {
    title: 'Gulangyu Breeze',
    price: '8',
    vegetarian: false,
    subtitles: 'Lychee Juice, Cranberry Juice, and Lime Juice'
  },
  {
    title: 'Tae Geuk Ki',
    price: '8',
    vegetarian: false,
    subtitles: 'Blood Orange, Pineapple Juice, and Butterfly Pea Flower'
  },
]