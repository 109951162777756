import React from 'react'
import VeganLogo from '../../images/vegan_logo.svg'

export default function MenuItem({item}) {

  const { title, price, subtitles, additionalElement } = item
  const key = `${title}-${price}`

  return (
    <li className='font-size-15 d-block' key={key}>
      <div className='font-weight-bold leaders pb-2'>
      <span className='d-inline-flex'>{title}{ false ? <img src={VeganLogo} className="ml-6 pb-2 vegan-menu-icon" alt='vegan'/>:  null}</span>
        <span>{price}</span>
      </div>
      <p className='mb-0'>{subtitles}</p>
      {additionalElement && (
      <div className='mt-4'>
        <p>{additionalElement}</p>
      </div>
      )}
    </li>
  )
}
