import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/all'

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

const fadeInBottom = (target, startOptions) => {
  gsap.from(target, {
    scrollTrigger: {
      trigger: target,
      start: startOptions
    },
    opacity: 0,
    duration: 1.5,
    y: 100,
    // clearProps: 'transform'
  })
}

const scrollTo = (target, duration = 1, offsetY = 100) => {
  gsap.to(window, {duration: duration, scrollTo: {y: target, offsetY: offsetY}})
}

export { fadeInBottom, scrollTo }