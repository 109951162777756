import React from "react";

export default function HanSang() {
  return (
    <div className="px-xxl-78">
      <div className="text-center mb-52 mb-xl-50 lunch-special-container">
        <div className="lunch-special-padding mb-32">
          <h5 className="font-size-32 font-family-garamond font-weight-semi-bold mb-12 mb-xl-15">
            <span className="desktop">Lunch Special</span>
            <span className="mobile">Lunch Special</span>
          </h5>
          <div className="font-weight-bold">
            <span className="font-size-20u">
              <p>"Han Sang"</p>
              <p>12 Delicacies Tasting Tray</p>
            </span>
            <div className="font-weight-normal mb-16">
              <p className="font-size-14 mt-14 mb-12">Choice of</p>
              <p className="font-size-16">Pork Cheek $25</p>
              <p className="font-size-16">Unagi $27</p>
              <p className="font-size-16">Short Rib $29</p>
            </div>
            <div>
              <p className="font-weight-normal font-size-14 line-height-16">
                * Limited number available.
              </p>
            </div>
          </div>
        </div>
        <div className="flex-center mb-32">
          <div className="w-75">
            <div className="horizontal-line w-33"></div>
            <div className="mt-32">
              <div className="font-weight-bold">
                <span className="font-size-20u">
                  <p>"Nang-Myeon & Galbi"</p>
                </span>
              </div>
              <div className="font-weight-normal mb-16">
                <p className="font-size-14 mt-14 mb-12">
                  Choice of Cold Broth or Spicy Sauce
                </p>
                <p className="font-size-16">Dwaeji Galbi (Pork)- $25</p>
                <p className="font-size-16">LA Galbi (Beef) - $29</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-center">
          <div className="w-75 ">
            <div className="horizontal-line w-33"></div>
            <div className="mt-32">
              <div className="font-weight-bold">
                <span className="font-size-20u">
                  <p>"Ssam Bap" – $25</p>
                </span>
              </div>
              <div className="font-weight-normal mb-16">
                <p className="font-size-14 mt-14 mb-12">Choice of</p>
                <p className="font-size-16">Beef Bulgoggie</p>
                <p className="font-size-16">Spicy Pork</p>
              </div>
              <div className="font-weight-normal mt-12">
                <span className="font-size-14 line-height-16">
                  <p>
                    Korean Miso Stew with Shaved Beef Brisket, Comes with Rice,
                    Side Dishes and Steamed Cabbage(Ssam)
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
