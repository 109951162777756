

export const BAN_CHAN = [
  {
    title: 'Kimchi Bok-Keum',
    price: '8',
    vegetarian: true,
    subtitles: 'Stir-Fried Kimchi'
  },
  {
    title: 'Spam',
    price: '6',
    vegetarian: true,
    subtitles: ''
  },
  {
    title: 'Kimchi',
    price: '5',
    vegetarian: false,
    subtitles: 'Napa Cabbage'
  },
  {
    title: 'Steamed Tofu',
    price: '5',
    vegetarian: false,
    subtitles: ''
  },
  {
    title: 'Gim',
    price: '5',
    vegetarian: true,
    subtitles: 'Baked Nori'
  },
  {
    title: 'Sunny Side Up Egg',
    price: '3',
    vegetarian: true,
    subtitles: ''
  },
  {
    title: 'Bowl of Rice',
    price: '2.5',
    vegetarian: true,
    subtitles: ''
  },
]

export const SSAM = [
  {
    title: 'Galbi',
    price: '45',
    vegetarian: false,
    subtitles: 'Grilled Soy Marinated Short Rib'
  },

  {
    title: 'Bossam',
    price: '34',
    vegetarian: false,
    subtitles: 'Crispy Pork Belly'
  },
  {
    title: 'Dwaeji Galbi',
    price: '36',
    vegetarian: false,
    subtitles: 'Grilled Soy Marinated Pork'
  },
  {
    title: 'Hang-Jeong',
    price: '32',
    vegetarian: false,
    subtitles: 'Grilled Pork Cheek'
  },
]

export const ANJU = [
  {
    title: 'Samchi Gui',
    price: '28',
    vegetarian: false,
    subtitles: 'Grilled Whole Spanish Mackerel, Pine Tree Leaf Infused Soy Sauce and Charred Lemon'
  },
  {
    title: 'KFC (Korean Fried Chicken)',
    price: '26',
    vegetarian: false,
    subtitles: 'Boneless Chicken Leg with a Choice of Sweet Soy Sauce (Sesame Seeds, Cilantro) or Spicy Gochujang Sauce (Peanuts, Scallions)'
  },
  {
    title: 'Spicy Seafood Stew',
    price: '26',
    vegetarian: false,
    subtitles: 'Seafood Medley, Soft Tofu, and Egg'
  },
  {
    title: 'Makgeolli Jogae Jjim',
    price: '24',
    vegetarian: false,
    subtitles: 'Manila Clams, Rice Wine, Dried Thai Chilli, Crispy Rice Puffs, and Crown Daisy'
  },
  {
    title: 'Oyster Tempura',
    price: '24',
    vegetarian: false,
    subtitles: 'Fresh Panko, Yuzu Kosho Mayo, and Fennel Muchim'
  },
  {
    title: 'Odeng-Tang',
    price: '24',
    vegetarian: false,
    subtitles: 'Fish Cake, Prawn, Crab, Daikon, and Anchovy Dashi'
  },
  {
    title: 'Chadol Doenjang',
    price: '22',
    vegetarian: false,
    subtitles: 'Korean Miso Stew with Shaved Beef Brisket'
  },
  {
    title: 'Tartare',
    price: '22',
    vegetarian: false,
    subtitles: 'Parmigiano, Yam String, Crostini with Scallion, and Gochujang Sauce'
  },
  {
    title: 'Tofu & Balhyo',
    price: '19',
    vegetarian: false,
    subtitles: 'Steamed Tofu, Fermented Pollack Roes (Myeong-Ran Jeot), Spicy Raw Baby Octopus (Nak-Ji Jeot) and Mustard Leaf Kimchi (Got Kimchi)'
  },
]

export const SSAL_GARU = [
  {
    title: 'Jeon the Korean Pancake',
    price: '26',
    vegetarian: true,
    subtitles: 'Cuttlefish, Asian Chive, Prawns, and Scallops'
  },
  {
    title: 'Ho-Gam Jeon',
    price: '25',
    vegetarian: true,
    subtitles: 'Zucchini, Potato, Dried Shrimp, Arugula, and Parmigiano'
  },
  {
    title: 'Kimchi Jeon',
    price: '24',
    vegetarian: true,
    subtitles: 'Kimchi, Bacon, Mozzarella, and Scallion'
  },
  {
    title: 'Tteokbokki',
    price: '24',
    vegetarian: true,
    subtitles: 'Gochu-Jang, Manila Clam, Prawn, Rice Cake, Baby Octopus, and Sesame Leaves',
    additionalElement: "— Add Mozzarella Cheese ($4)"
  },
  {
    title: 'Jap-Chae',
    price: '21',
    vegetarian: true,
    subtitles: 'Glass Noodle, Zucchini, Shimeji Mushroom, Woodear Mushroom, and Assorted Vegetables'
  },
  {
    title: 'Kimchi Fried Rice',
    price: '20',
    vegetarian: false,
    subtitles: 'Kimchi, Bacon, Poached Egg, and Crispy Shallot',
    additionalElement: "— Add Mozzarella Cheese ($4)"
  },
  {
    title: 'Bibim Kooksoo',
    price: '18',
    vegetarian: true,
    subtitles: 'Buckwheet Noodle, Herby Salad, Pear Chojang and Peanuts'
  },
  {
    title: 'Kimchi Mul-Kooksoo',
    price: '18',
    vegetarian: true,
    subtitles: 'Cold Buckwheat Noodle, Kimchi Broth, Egg, and Sesame Leaves'
  },
  {
    title: 'Mandu the Korean Dumpling',
    price: '13',
    vegetarian: true,
    subtitles: 'Pork, Chive, and Young Rice'
  },
]