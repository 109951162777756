import React, { useEffect } from 'react';
import Navbar from '../components/NavBar'
import MainHeader from '../components/MainHeader';
import AboutUs from '../components/AboutUs';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import { fadeInBottom } from '../components/util/animation';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Contact from '../components/Contact';
import MainLoadingPage from '../components/ExtraComponents/MainLoadingPage';
import { LoadingContextProvider } from '../components/context/LoadingContext';

library.add(fab, faBars)

const IndexPage = () => {

  useEffect(() => {

    const hash = window.location.hash

    if ( hash ) return 
    fadeInBottom('#aboutUs', '100px 100%');
    fadeInBottom('#ourMenu', '100px 100%');
    fadeInBottom('#contactUsSection', '200px 100%');
  }, [])

  return (
    <LoadingContextProvider>
      <div className={`container-fluid`} >
        <MainLoadingPage/>
        <Navbar/>
        <MainHeader />
        <AboutUs />
        <Menu />
        <Contact/>
        <Footer />
      </div>
    </LoadingContextProvider>
  );
};

export default IndexPage;
