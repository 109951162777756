import React, { useEffect, useRef } from 'react'
import Swiper, { Pagination, Autoplay } from 'swiper'
import SwiperSlide from './SwiperSlide';
import image1 from '../images/header1.jpg'
import image2 from '../images/header2.jpg'
import image3 from '../images/header3.jpg'
import image4 from '../images/header4.jpg'

export default function MainHeader() {

  const swiper = useRef(null)

  useEffect(() => {
    Swiper.use([Pagination, Autoplay])
    swiper.current = new Swiper('#headerSwiper', {
      loop: true,
      autoplay: {
        delay: 7000
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: false,
      },
    })
  }, [])

  return (
    <section className='container-fluid' id='mainHeader'>
      <div className='row'>
        <div className='col-12'>
          <div className='swiper' id='headerSwiper'>
            <div className='swiper-wrapper'>

              <SwiperSlide
                imageSource={image1}
                desktopText={<span className='desktop'>Zoomak, a new category for Korean cuisine in Vancouver.</span>}
                mobileText={<span className='mobile'>Zoomak, <br/>a new category for Korean cuisine</span>}
                underlineId={'ulHeaderOne'}
              />

              <SwiperSlide
                imageSource={image2}
                desktopText={<span className='desktop'>Zoomak, a new category for Korean cuisine in Vancouver.</span>}
                mobileText={<span className='mobile'>Zoomak, <br/>a new category for Korean cuisine</span>}
                underlineId={'ulHeaderTwo'}
              />

              <SwiperSlide
                imageSource={image3}
                desktopText={<span className='desktop'>Zoomak, a new category for Korean cuisine in Vancouver.</span>}
                mobileText={<span className='mobile'>Zoomak, <br/>a new category for Korean cuisine</span>}
                underlineId={'ulHeaderThree'}
              />

              <SwiperSlide
                imageSource={image4}
                desktopText={<span className='desktop'>Zoomak, a new category for Korean cuisine in Vancouver.</span>}
                mobileText={<span className='mobile'>Zoomak, <br/>a new category for Korean cuisine</span>}
                underlineId={'ulHeaderFour'}
              />

            </div>

            <div className="swiper-pagination swiper-pagination-bullets"></div>

          </div>
        </div>
      </div>
    </section>
  )
}
