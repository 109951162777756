import React, { useEffect } from 'react'
import Swiper from 'swiper'
import SwiperMenuPicture from './SwiperMenuPicture'
import Image1 from '../../images/menu/latenight/night0.jpg'
import Image2 from '../../images/menu/latenight/night1.jpg'
import Image3 from '../../images/menu/latenight/night2.jpg'
import AnjuHanSang from '../ExtraComponents/AnjuHanSang';
import MenuFooterText from '../ExtraComponents/MenuFooterText';

export default function NightMenu() {

  let images = [
    { src: Image1, alt: 'latenight_image1'},
    { src: Image2, alt: 'latenight_image2'},
    { src: Image3, alt: 'latenight_image3'},
  ]

  const swiperMenuImages = images.map((item, index) =>
    <SwiperMenuPicture
      src={item.src}
      alt={item.alt}
      key={`${item.alt}_${index}`}
    />
  )

  useEffect(() => {
    new Swiper('#nightPicturesSwiper', {
      autoHeight: true,
      initialSlide: 1,
      noSwiping: false,
      slidesPerView: 2,
      centeredSlides: true,
      noSwipingClass:'no-nested-swiping',
      spaceBetween: 20,
      freeMode: true,
      nested: true,
      breakpoints: {
        1200: {
          initialSlide: 1,
          spaceBetween: 24,
          slidesPerView: 5,
        }
      }
    })
  }, [])

  return (
    <div className='swiper-slide swiper-no-swiping mt-xl-28 swiper-menu-slide'>
      <div className='container'>
        <main className='row'>
          <div className='offset-xl-4 col-xl-4 col-12'>
            <AnjuHanSang />
            <MenuFooterText />
          </div>
        </main>
      </div>
      
        <div className='swiper' id='nightPicturesSwiper'>
          <div className='swiper-wrapper no-nested-swiping'>
            { swiperMenuImages }
          </div>
        </div>
      
    </div>
  )
}
