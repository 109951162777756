import React from "react";

export default function AnjuHanSang() {
  return (
    <div className="px-xxl-78">
      <div className="text-center mb-52 mb-xl-50 lunch-special-container">
        <div className="lunch-special-padding">
          <h5 className="font-size-32 font-family-garamond font-weight-semi-bold mb-12 mb-xl-15">
            Bunsik Han Sang $40
          </h5>
          <div className="font-size-20u font-weight-bold">
            <span className="desktop">6 Kinds of Korean Street Food</span>
            <span className="mobile">
              <p className="mt-n5">6 Kinds of Korean Street Food</p>
            </span>
            <p className="font-weight-normal mt-12 font-size-15">
              Only available Mon - Thurs, after 8:30pm
            </p>
            <p className="font-weight-normal font-size-14 line-height-16 mt-16">
              * Limited number available.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
